<template>
  <div class="articles">
    <!--    <banner :hover="this.article.title" :randomSrc="this.article.banner" :src="this.article.banner"-->
    <!--            id="banner"></banner>-->
    <div class="site-content animate">
      <!-- 文章目录 -->
      <div id="article-menus">
        <el-divider>文章目录</el-divider>
        <menu-tree :menus="menus" child-label="child"></menu-tree>
        <el-divider><a style="color: deepskyblue">修改履历</a></el-divider>
        <div id="article-operations">
          <ol>
            <li style="list-style-position: inside;margin-top: 20px" v-for="(item, index) in operations"
                :key="item.OBJECT_ID">
              <el-tag size="small">{{ item.CREATE_TIME }}</el-tag>
              用户：{{ item.NICK_NAME }}{{ item.OP_REASON }}
              <el-tooltip v-if="item.REMARK" class="item" effect="dark" :content="item.REMARK" placement="top-start">
                <a :href="item.REMARK" style="color: skyblue;font-size: 10px;font-weight: bold">
                  Difference
                </a>
              </el-tooltip>
            </li>
          </ol>
          <el-pagination
              v-if="operations.length>0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParam.pageNum"
              :page-size="queryParam.pageSize"
              layout="total, prev, pager, next"
              :total="operations[0].TOTAL">
          </el-pagination>
        </div>
      </div>
      <main class="site-main">
        <article class="hentry" id="show" ref="content">
          <!-- 文章头部 -->
          <header class="entry-header">
            <!-- 标题输出 -->
            <h1 class="entry-title">{{ this.article.title }}</h1>
            <hr>

            <div class="breadcrumbs">
              <div id="crumbs">
                <!--TODO 这里做个用户首页-->
                <a href="/person" style="color: black;font-weight: bold">{{ this.article.createBy }}</a>
                <a style="color:grey;">发布于：{{ this.article.pubTime + this.article.address }}</a>
                <a class="lastUpdateTime" style="float:right;color: grey;">最后更新时间：{{
                    this.article.lastUpdateTime
                  }}</a>
              </div>
            </div>


            <baidu-map :center="{lng: this.article.x, lat: this.article.y}" :scroll-wheel-zoom="true" :zoom="17"
                       class="baidu-map-css"
                       ak="OGKcSaGy0UHqBxXniK0u3Ib00hhZfGkI">
              <!--比例尺控件左上角-->
              <bm-scale anchor="BMAP_ANCHOR_TOP_LEFT"></bm-scale>
              <!--缩放控件右下角-->
              <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>
              <!--跳动标记-->
              <bm-marker :position="{lng: this.article.x, lat: this.article.y}" :dragging="true"
                         animation="BMAP_ANIMATION_BOUNCE"></bm-marker>
            </baidu-map>

          </header>
          <div class="entry-content" v-highlight>
            <!--markdown-body 会变黑、v-highlight会让代码变高亮-->
            <div class="github-markdown-light" v-highlight>
              <VueMarkdown :source="this.article.content" v-highlight></VueMarkdown>
            </div>
          </div>
          <el-button @click="handleDialog">编辑</el-button>
          <el-button type="warning" @click="handleDelDialog">删除</el-button>


          <el-dialog title="二次确定" :visible.sync="delArticleDialog" width="30%" :close-on-click-modal="true"
                     append-to-body>
            <el-input v-model="checkKey" placeholder="请输入弱密令" @input="changeArticleValidFlag"/>
          </el-dialog>
          <addArticle title="文章管理" v-if="addArticleDialog" ref="addArticle"/>
          <!-- 文章底部 -->
          <section-title>
            <footer class="post-footer">
              <!-- 阅读次数 -->
              <div class="post-like">
                <i class="iconfont iconeyes"></i>
                <span class="count">{{ this.article.viewsCount }}</span>

                <img style="margin:0 3px 0 10px"
                     src="@/assets/Heart.svg"
                     alt="点赞"
                     @click="likeThis('Y')"
                     :class="{ bounced: likeBounced }"
                />
                <span class="count"> {{ this.article.likeCount }}</span>

                <img style="margin:0 3px 0 10px"
                     src="@/assets/brokeHeart.svg"
                     alt="踩"
                     @click="likeThis('N')"
                     :class="{ bounced: unlikeBounced }"
                />
                <span class="count">{{ this.article.unlikeCount }}</span>
              </div>
              <div class="donate" @click="showDonate=!showDonate">
                <span>赏</span>
                <ul class="donate_inner" :class="{'show':showDonate}">
                  <li class="wedonate"><img src="http://cdn.fengziy.cn/ideasLearn/wexin_pay.png">
                    <p>微信</p></li>
                  <li class="alidonate"><img src="http://cdn.fengziy.cn/ideasLearn/ali_pay.jpg">
                    <p>支付宝</p></li>
                </ul>
              </div>
              <!-- 文章标签 -->
              <div class="post-tags">
                <i class="iconfont iconcategory"></i>
                <router-link to="/category/web">Web</router-link>
              </div>
            </footer>
          </section-title>

          <!--声明-->
          <div class="open-message">
            <p>声明：ideasRanch|版权所有，违者必究|如未注明，均为原创|本网站采用<a
                href="https://creativecommons.org/licenses/by-nc-sa/3.0/" target="_blank">BY-NC-SA</a>协议进行授权</p>
            <p>转载：转载请注明原文链接 - <a href="/">{{ this.article.title }}</a></p>
          </div>
          <!--评论-->
          <div class="comments">
            <div v-if="comments.length !==0">
              <comment v-for="item in comments" :key="item.id" :comment="item.parent" @getList="getComment">
                <template v-if="item.comments.length">
                  <comment v-for="reply in item.comments" :key="reply.id" :comment="reply"
                           @getList="getComment"></comment>
                </template>
              </comment>
            </div>
          </div>
          <!--              <span>还没有评论哦，总要说点什么吧<i class="iconfont iconcomment"></i></span>-->
          <el-row>
            <el-col :span="18">
              <el-input v-model="commentFormData.commentContent" placeholder="尝试评论些什么" clearable>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button type="text" @click="addComment()">回复<i class="iconfont iconcomment"></i></el-button>
            </el-col>
          </el-row>
        </article>
      </main>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/banner'
import sectionTitle from '@/components/section-title'
import comment from '@/components/comment'
import menuTree from '@/components/menu-tree'
import AddArticle from "@/components/addArticle.vue";
import {addComment, deleteArticle, doLike, fetchAddressByIp, fetchComment, fetchList, fetchOperations} from '../api'
// 引入markdown
import VueMarkdown from 'vue-markdown'
import markdownIt from 'markdown-it'
// 引入水印
import Cookies from "js-cookie";

//局部注册方式
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmScale from 'vue-baidu-map/components/controls/Scale'
import BmNavigation from 'vue-baidu-map/components/controls/Navigation'
import BmMarker from 'vue-baidu-map/components/overlays/Marker'

export default {
  name: 'articles',
  components: {
    BaiduMap,
    BmScale, //比例尺
    BmNavigation, //缩放
    BmMarker, //标记
    AddArticle,
    Banner,
    sectionTitle,
    comment,
    menuTree,
    VueMarkdown

  },

  data() {
    return {
      showDonate: false,
      comments: [],
      checkKey: "",
      unlikeBounced: false,
      likeBounced: false,
      queryParam: {
        ip: "",
        pageSize: 5,
        pageNum: 0,
        articleId: 0
      },
      operations: [],
      addArticleDialog: false,
      delArticleDialog: false,
      parsedMarkdown: "",
      article: {
        address: "",
        x: "",
        y: "",
        adcode: ""
      },
      likeFormData: {
        articleId: "",
      },
      formData: {
        articleId: "",
        validFlag: ""
      },
      commentFormData: {
        userId: "",
        articleId: "",
        userName: "",
        commentContent: ""
      },
      menus: []
    }
  },

  methods: {
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange(size) {
      this.queryParam.pageSize = size;
      this.getOperation()
    },
    handleCurrentChange(currentPage) {
      this.queryParam.pageNum = currentPage;
      this.getOperation()
    },
    // 点赞
    likeThis(likeFlag) {
      this.likeFormData.articleId = this.$route.params.id
      if ('Y' === likeFlag) {
        //   点赞
        console.log('我喜欢你的文章')
        this.likeBounced = true;
        // 如果动画只执行一次，并且你想要在动画结束后移除类，可以设置一个定时器
        setTimeout(() => {
          this.likeBounced = false;
        }, 800); // 假设动画持续时间为800毫秒
        this.likeFormData.likeFlag = 'Y'
      } else if ('N' === likeFlag) {
        //   踩
        console.log('我讨厌你的文章')
        this.unlikeBounced = true;
        // 如果动画只执行一次，并且你想要在动画结束后移除类，可以设置一个定时器
        setTimeout(() => {
          this.unlikeBounced = false;
        }, 800); // 假设动画持续时间为800毫秒
        this.likeFormData.likeFlag = 'N'
      }
      this.likeFormData.userId = this.$store.getters.userInfo.userId;
      doLike(this.likeFormData).then(res => {
        if (res.code === 200) {
          this.getArticle()
          this.getOperation()
        }
      })
    },
    addComment() {
      this.commentFormData.userId = this.$store.getters.userInfo.userId;
      this.commentFormData.userName = this.$store.getters.userInfo.userName;
      this.commentFormData.articleId = this.$route.params.id
      addComment(this.commentFormData).then(res => {
        if (res.code === 200) {
          location.reload()
        }
      })
    },
    changeArticleValidFlag() {
      this.formData.articleId = this.$route.params.id
      this.formData.userId = this.$store.getters.userInfo.userId;
      if (this.checkKey === '!') {
        this.formData.validFlag = "N"
        deleteArticle(this.formData).then(res => {
          if (res.code === 200) {
            this.$router.push('/')
            this.$message.success("completed")
          }
        })
      } else {
        this.$message.error("that 's incorrect！")
      }
    },
    // 打开删除框
    handleDelDialog() {
      this.delArticleDialog = true;
    },
    // 按钮方法
    handleDialog() {
      const articleId = this.$route.params.id
      this.addArticleDialog = true;
      this.$nextTick(() => {
        this.$refs.addArticle.init(articleId);
      });
    },
    async getOperation() {
      // 获取路由传入的ID
      const articleId = this.$route.params.id
      this.queryParam.articleId = articleId
      this.queryParam.groupType = "article"
      fetchOperations(this.queryParam).then(res => {
        if (res.code === 200) {
          this.operations = res.data
        }
      })
    },
    async getArticle() {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "加载中", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      // 获取路由传入的ID
      const articleId = this.$route.params.id
      console.log("文章ID", articleId)
      this.queryParam.articleId = articleId
      fetchList(this.queryParam).then(async res => {
        // 获取文章信息成功后
        if (res.code === 200) {
          // 拿到文章信息非空
          if (res.data.items.length !== 0) {
            this.article = res.data.items[0]
            // 调用解析接口
            const formData = new FormData();
            formData.append('ip', res.data.items[0].articleReleaseIp);
            // 解析IP具体位置
            await fetchAddressByIp(formData).then(res => {
              if (res.code === 200) {
                this.article.adcode = res.data.adcode
                this.article.address = res.data.address
                this.article.x = res.data.x
                this.article.y = res.data.y
              } else {
                this.article.address = "未知星球"
              }
            })

            this.createMenus()
            loading.close()
          } else {
            alert("尚无文章" + articleId)
            this.$router.push('/')
          }
        }
      })
    },
    async getComment() {
      // 创建一个 loading 实例并赋值给 loading 变量
      let loading = this.$loading({
        text: "加载中", // 设置 loading 文本为 "加载中"
        spinner: "el-icon-loading", // 使用 Element UI 提供的加载图标
        background: "rgba(0, 0, 0, 0.7)", // 设置 loading 遮罩层背景色为半透明黑色
        target: document.querySelector("body"), // 将 loading 遮罩层挂载到页面 body 元素上
      });
      // 获取路由传入的ID
      const articleId = this.$route.params.id
      console.log("文章ID", articleId)
      this.queryParam.articleId = articleId
      fetchComment(this.queryParam).then(res => {
        this.comments = res.data || []
        loading.close()
      }).catch(err => {
        console.log(err)
      })
    },
    fetchH(arr, left, right) {
      if (right) {
        return arr.filter(item => item.offsetTop > left && item.offsetTop < right)
      } else {
        return arr.filter(item => item.offsetTop > left)
      }
    },
    // 生成目录
    createMenus() {
      let arr = []
      const md = markdownIt({
        html: true,
        linkify: true,
        typographer: true
      })
      for (let i = 6; i > 0; i--) {
        // 解析 Markdown 为 HTML
        this.parsedMarkdown = md.render(this.article.content)
        // 使用 DOMParser 来解析 HTML 字符串为 DOM
        const parser = new DOMParser()
        const doc = parser.parseFromString(this.parsedMarkdown, 'text/html')

        // 获取文档的滚动位置
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        let temp = []
        // 查找所有 h4 元素（由 #### 生成）
        let e = doc.querySelectorAll(`h${i}`)
        if (e.length) {
          for (let j = 0; j < e.length; j++) {
            // 计算标题的顶部偏移量，包括文档的滚动位置
            let offsetTop = e[j].offsetTop + scrollTop;
            let right = (j + 1 < e.length) ? e[j + 1].offsetTop + scrollTop : undefined;
            let child = this.fetchH(arr, offsetTop, right);
            e[j].id = e[j].innerText;
            console.log("e", e[j])
            temp.push({
              h: i,
              title: e[j].innerText,
              // id: e[j].getAttribute("dataIndex"),
              id: e[j].innerText,
              offsetTop: offsetTop,
              child
            })
          }
        }
        if (temp.length) {
          arr = temp
        }
      }
      this.menus = arr
    }
  },
  beforeDestroy() {
    this.$watermark.set("", this.$refs.content);
  },
  mounted() {
    // document.getElementsByClassName("el-pagination__jump").
    let date = new Date();
    // 用于水印的文字，第一行为用户名，第二行为时间
    let text = Cookies.get('nickName') + "\n" + date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    this.$watermark.set(text, this.$refs.content)
  },
  created() {


    this.getArticle()
    this.getOperation()
    this.getComment()
  }
}
</script>
<style scoped lang="less">
.site-content {
  position: relative;

  .site-main {
    padding: 80px 0 0 0;
  }
}

#article-operations {
  position: sticky;
  font-size: 12px;
  margin: 30px 0 50px 0;
}

#article-menus {
  height: 0;
  position: sticky;
  top: 0;
  //box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  border-radius: 3px;
  padding: 15px;
  width: 400px;
  transform: translateX(-120%) translateY(150px);
  font-size: 14px;
}

.baidu-map-css {
  background: grey;
  width: 90%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 50px auto;
  height: 300px;
}

article.hentry {
  .entry-header {
    .entry-title {
      font-size: 23px;
      font-weight: 600;
      color: #737373;
      margin: 0.67em 0;

      &:before {
        content: "#";
        margin-right: 6px;
        color: #d82e16;
        font-size: 20px;
        font-weight: 600;
      }
    }

    hr {
      height: 1px;
      border: 0;
      background: #EFEFEF;
      margin: 15px 0;
    }

    .breadcrumbs {
      font-size: 14px;
      color: #D2D2D2;
      text-decoration: none;
      margin-bottom: 30px;
    }
  }

  .entry-content {
  }

  footer.post-footer {
    width: 100%;
    padding: 20px 10px;
    margin-top: 30px;
    height: 65px;
    position: relative;

    i {
      font-size: 18px;
      margin-right: 5px;
    }

    .post-like {
      float: right;
      margin: 7px 0 0 20px;
    }

    .post-share {
      float: right;
      list-style: none;
      margin-right: 20px;
    }

    .donate {
      float: left;
      line-height: 36px;
      border-radius: 100%;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border: 1px solid #2B2B2B;

      &:hover {
        border: 1px solid goldenrod;

        span {
          color: goldenrod;
        }
      }

      span {
        color: #2B2B2B;
        padding: 10px;
        position: relative;
        cursor: pointer;
      }

      .donate_inner {
        display: none;
        margin: 0;
        list-style: none;
        position: absolute;
        left: 80px;
        top: -40px;
        background: #FFF;
        padding: 10px;
        border: 1px solid #ddd;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .08);
        border-radius: 3px;

        &.show {
          display: block;
        }

        li {
          float: left;
        }

        img {
          width: 100px;
        }

        p {
          text-align: center;
          font-size: 15px;
          color: #D2D2D2;
          line-height: 1rem;
        }
      }

      .donate_inner:after, .donate_inner:before {
        content: "";
        position: absolute;
        left: 0;
        bottom: 45%;
        margin-left: -8px;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fff;
      }

      .donate_inner:before {
        left: -1px;
        border-right: 8px solid #ddd;
      }

    }

    .post-tags {
      margin: 7px 0 0 20px;
      float: left;
      text-transform: uppercase;

      a:hover {
        color: #ff6d6d;
      }
    }
  }

  .open-message {
    margin: 50px 0;
    position: relative;
    background: #2B2B2B;
    padding: 10px 30px;
    border-radius: 3px;
    font-size: 14px;
    color: #fff;

    &:after {
      content: "";
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #2B2B2B;
      position: absolute;
      top: -8px;
      left: 48%;
    }

    p {
      margin: 10px 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    a {
      color: #A0DAD0;
      padding: 0 5px;
    }
  }
}

@media (max-width: 1490px) {
  #article-menus {
    display: none;
  }
}

@media (max-width: 800px) {
  #article-menus {
    display: none;
  }
}

/* 适用于小于600px宽度的设备（通常是移动设备） */
@media screen and (max-width: 600px) {
  .lastUpdateTime {
    float: left;
  }

  .baidu-map-css {
    height: 200px;
  }
}

@keyframes bounce {
  /* 动画定义 */
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

.bounced {
  animation: bounce 0.8s;
}
</style>
